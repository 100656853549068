import { useState } from "react";
import { Link } from "react-router-dom";

export default function Burger() {
  const [isNavOpen, setIsNavOpen] = useState(false);

  return (
    <div className="w-full">
      <nav>
        {/* Burger Icon */}
        <section className="MOBILE-MENU flex justify-end pb-2">
          <div
            className="HAMBURGER-ICON space-y-1"
            onClick={() => setIsNavOpen((prev) => !prev)}
          >
            <span className="block h-0.5 w-5 bg-white"></span>
            <span className="block h-0.5 w-5 bg-white"></span>
            <span className="block h-0.5 w-5 bg-white"></span>
          </div>

          <div className={isNavOpen ? "showMenuNav" : "hideMenuNav"}>
            <div
              className="absolute top-0 right-0 px-8 py-8"
              onClick={() => setIsNavOpen(false)}
            >
              <svg
                className="h-8 w-8"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </div>

            {/* Burger menu */}
            <ul className="text-center">
              <li className="my-6 uppercase">
                <Link to="/">
                  Home
                </Link>
              </li>
              <li className="my-6 uppercase">
                <Link
                  onClick={() => (window.location = "mailto:kenneth@oasisc.co")}
                >
                  Contact
                </Link>
              </li>
              <li>
                <Link to="/Policy" className="my-6 uppercase">
                  Privacy Policy & GDPR
                </Link>
              </li>
            </ul>
          </div>
        </section>
      </nav>
      <style>
        {`
      .hideMenuNav {
        display: none;
      }
      .showMenuNav {
        display: block;
        position: absolute;
        width: 100%;
        height: 50%;
        top: 0;
        left: 0;
        background: rgba(0,47,63,0.9);
        z-index: 10;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
      }
    `}
      </style>
    </div>
  );
}
