import React from "react";
import NavBar from "../components/NavBar";
import CreatingValue from "../components/CreatingValue";
import OperatingGlobally from "../components/OperatingGlobally";
import CoreProducts from "../components/CoreProducts";
import GetInTouch from "../components/GetInTouch";
import Footer from "../components/Footer";

export default function Home() {
  return (
    <div className="font-body">
      <div className="h-screen px-[3rem] pt-5 bg-main bg-cover text-white">
        <NavBar />
        <CreatingValue />
      </div>
      <div className="px-[3rem] md:pr-0 ">
        <OperatingGlobally />
      </div>
      <div className="px-[3rem] py-[3rem] bg-teal text-white">
        <CoreProducts />
      </div>
      <div className="px-[3rem] py-[2rem] lg:py-[5rem]">
        <GetInTouch />
      </div>
      <div className="px-[3rem] bg-teal text-white">
        <Footer />
      </div>
    </div>
  );
}
