import React from "react";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import PolicyGDPR from "../components/PolicyGDPR";

export default function Policy() {
  return (
    <div className="p-[3rem] bg-teal font-body text-white">
      <NavBar />
      <PolicyGDPR />
      <Footer />
    </div>
  );
}
