import React, { useState } from "react";
import { Link } from "react-router-dom";
import OCLogo from "../logo.svg";
import Burger from "./Burger";

export default function NavBar() {
  const [menu, showMenu] = useState(false);

  return (
    <div className="text-sm flex">
      <div className="w-1/2 lg:w-1/4">
        <img src={OCLogo} alt="Oasis Commodities Logo" />
      </div>

      {/* sm / mg / lg navigation */}
      <div className="hidden sm:flex flex-col justify-end w-1/4 pb-3">
        <Link to="/" className="flex justify-end pr-[2rem]">
          Home
        </Link>
      </div>
      <div className="hidden sm:flex flex-col justify-end w-1/4 pb-3">
        <Link onClick={() => (window.location = "mailto:kenneth@oasisc.co")}>
          Contact
        </Link>
      </div>

      {/* burger icon */}
      <div className="w-1/2 sm:w-1/4 flex flex-col justify-end pb-1 sm:pb-2">
        <Burger />
      </div>
    </div>
  );
}
