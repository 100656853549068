import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Policy from "./pages/Policy";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/Policy" element={<Policy />} />
      </Routes>
    </BrowserRouter>
  );
}
