import React from "react";
import { HashLink } from "react-router-hash-link";
import Earth from "../imgs/spinning-earth.mp4";
import RightArrow from "../imgs/right-arrow.svg";

export default function OperatingGlobally() {
  return (
    <div className="py-2 grid grid-cols-1 sm:grid-cols-2 gap-2">
      <div className="w-full sm:w-2/3 flex flex-col justify-center">
        <div className="py-10 w-full grid grid-flow-row auto-rows-auto gap-2">
          <div className="flex flex-col justify-end">
            <p className="font-title text-blue text-2xl lg:text-3xl">
              Operating Globally
            </p>
          </div>

          <div className="flex flex-col justify-center">
            <p className="font-body text-black text-sm md:text-base">
              We advance our clients’ business by addressing all aspects of
              their commodity-related needs. Providing a one-stop-shop for
              marketing, trading, hedging, transporting, financing, advising,
              and investing in commodities.
            </p>
          </div>

          <HashLink to="/#CoreProducts">
            <div className="text-blue flex flex-row">
              <p>Discover More</p>
              <div className="pl-3 flex flex-col justify-center animate-pulse">
                <img src={RightArrow} alt="Down arrow" />
              </div>
            </div>
          </HashLink>
        </div>
      </div>

      <div className="flex flex-col justify-center text-center">
        <video autoPlay muted loop src={Earth} id="video"/>
      </div>
    </div>
  );
}
