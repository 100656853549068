import React from "react";
import DownArrow from "../imgs/down-arrow.svg";

export default function CreatingValue() {
  return (
    <div className="w-full h-4/5 text-2xl lg:text-3xl">
      <div className="w-full sm:w-3/5 md:w-1/2 h-3/5 lg:h-4/5 flex flex-col justify-center relative">
        <p className="font-title pb-[1rem]">Creating Value</p>
        <p className="pt-[1rem]">
          A world leader in commodity markets, spearheading solutions for
          tomorrow.
        </p>
      </div>

      <div className="flex justify-center">
        <div className="absolute bottom-2 animate-bounce">
          <img src={DownArrow} alt="Down arrow" />
        </div>
      </div>
    </div>
  );
}
